import http from "@/helpmate/services/http-common";


class OrgStructureDataService {

    getData(queryParams={}) {
        return http.get(`/org_structure/`, {params: queryParams});
    }

}

export default new OrgStructureDataService();
