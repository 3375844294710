import http from "@/helpmate/services/http-common";
import {UniqueString} from 'unique-string-generator';
import AbstractDataService from "@/helpmate/services/AbstractDataService";

class UserDataService extends AbstractDataService {

    constructor() {
        super('/users/user');
    }

    createEmpty() {
        return http.post(`${this.api_url}/`, {
            'username': UniqueString(),
        });
    }

    // ---

    getProfile() {
        return http.get(`${this.api_url}/profile/`);
    }

    updateProfile(data) {
        return http.post(`${this.api_url}/update_profile/`, data);
    }

    assignGroup(user_id, group_id) {
        return http.post(`${this.api_url}/assign_group/`, {
            'user_id': user_id,
            'group_id': group_id,
        });
    }

    removeGroup(user_id, group_id) {
        return http.post(`${this.api_url}/remove_group/`, {
            'user_id': user_id,
            'group_id': group_id,
        });
    }

    assignSuperuser(user_id) {
        return http.post(`${this.api_url}/assign_superuser/`, {
            'user_id': user_id,
        });
    }

    removeSuperuser(user_id) {
        return http.post(`${this.api_url}/remove_superuser/`, {
            'user_id': user_id,
        });
    }
}

export default new UserDataService();
