import http from "@/helpmate/services/http-common";


class ContractTotalBaseDataService {

    getData(queryParams) {
        return http.get(`/total_cash_base/`, {params: queryParams});
    }

}

export default new ContractTotalBaseDataService();
