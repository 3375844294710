<script setup>
import ContractDataService from "@/services/ContractDataService";

</script>


<template>
  <NavBar :title="`Статистика по договорам`"></NavBar>
  <div class="container">
    <div class="row"></div>
      <div class="col-md-12">
        <p>Количество договоров: {{data.total_contracts_count}}</p>
        <div class="bg-light p-3 mt-3">
          <HelpmateTabs>
            <HelpmateTab title="Основные данные" active="true">
              <ContractTotalBase :autoUpdate="false"></ContractTotalBase>
            </HelpmateTab>
            <HelpmateTab title="Расчет фактической стоимости">
              <p>Трудозатраты с налогами, без накладных и прибыли</p>
              <ContractTotalFact :autoUpdate="false"></ContractTotalFact>
            </HelpmateTab>
            <HelpmateTab title="Расчет требуемой стоимости">
              <p>Трудозатраты с налогами, накладными и прибылью</p>
              <ContractTotalTreb :autoUpdate="false"></ContractTotalTreb>
            </HelpmateTab>
        </HelpmateTabs>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import ContractTotalFact from "@/components/ContractTotal/ContractTotalFact.vue";
import ContractTotalTreb from "@/components/ContractTotal/ContractTotalTreb.vue";
import ContractTotalBase from "@/components/ContractTotal/ContractTotalBase.vue";
import HelpmateTab from "@/components/HelpmateTab.vue";
import HelpmateTabs from "@/components/HelpmateTabs.vue";

export default {
  name: 'StatsContractsView',
  components: {
    ContractTotalTreb,
    ContractTotalFact,
    ContractTotalBase,
    NavBar,
    HelpmateTab,
    HelpmateTabs
  },
  data() {
    return {
      isLoading: true,
      data: {
        total_contracts_count: 0
      }
    }
  },
  methods: {
    updateData() {
      ContractDataService.getAll().then((response) => {
        this.data.total_contracts_count = response.data.count;
      });
    }
  },
  mounted() {
    this.updateData();
  }
};
</script>
