<template>
    <NavBar :title="'Орг структура'"></NavBar>

    <OrgChart :data="data"></OrgChart>
</template>

<script>
import OrgChart from "@/components/OrgChart.vue";
import NavBar from "@/components/NavBar.vue";
import OrgStructureDataService from "@/services/OrgStructureDataService";

export default {
  name: 'OrgStructureView',
  components: {
    NavBar,
    OrgChart
  },
  methods: {
    getData() {
      OrgStructureDataService.getData().then(response => {
        this.data = response.data;
      });
    }
  },
  data() {
    return {
      data: []
    };
  },
  created() {
    this.getData();
  }
};
</script>
