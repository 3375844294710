<template>

    <HelpmateTable :tableContext="tableContext" :dataService="TaskDataService()" :columns="columns"></HelpmateTable>

</template>

<script>
import TaskDataService from '@/services/TaskDataService';
import HelpmateTable from "@/helpmate/components/HelpmateTable.vue";

export default {
  name: 'TaskListComponent',
  components: {
    HelpmateTable,
  },
  props: {
    tableContext: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      columns: [
        {field: 'id', fieldType: 'link', title: 'Подробнее', linkTemplate: '/tasks/detail/#ID#', readonly: true},
        {field: 'code', fieldType: 'text', title: 'Шифр'},
        {field: 'title', fieldType: 'text', title: 'Название'},
        {field: 'status', fieldType: 'status', title: 'Статус', readonly: true},
        {field: 'contract_amount', fieldType: 'priceint', title: 'Стоимость по договору, руб.', aggregate: 'sum'},
        {field: 'actual_amount', fieldType: 'priceint', title: 'Фактическая стоимость, руб.', readonly: true, aggregate: 'sum', highlightMoreThenField: 'contract_amount'},
        {field: 'cost_amount', fieldType: 'priceint', title: 'Стоимость трудозатрат, руб.', readonly: true, aggregate: 'sum'},
        {field: 'date_at', fieldType: 'date', title: 'Дата исполнителя', highlightExpired: true},
        {field: 'estimated_amount', fieldType: 'price', title: 'Т план', aggregate: 'sum'},
        {field: 'time_spent', fieldType: 'price', title: 'Т факт', readonly: true, aggregate: 'sum', highlightMoreThenField: 'estimated_amount'},
        {field: 'content_comment', fieldType: 'text', title: 'Примечание'},
      ],
    }
  },
  methods: {
    TaskDataService() {
      return TaskDataService
    },
  }
};
</script>
