<template>
<nav class="navbar navbar-expand-md navbar-light  bg-light">
  <div class="container-fluid">
    <span
        v-for="(obj, index) in breadcrumbs"
        :key="index"
    >
      <RouterLink :to="obj.url"><small>{{obj.title}} &rarr;</small></RouterLink> &nbsp;
    </span>
    <span class="navbar-brand">{{title}}</span>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Переключить навигацию">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <div class="navbar-nav me-auto mb-2 mb-md-0">
      <slot></slot>
      </div>
<!--      <ul class="navbar-nav me-auto mb-2 mb-md-0">-->

<!--        <li class="nav-item">-->
<!--          <a class="nav-link active" aria-current="page" href="#">Главная</a>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <a class="nav-link" href="#">Ссылка</a>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Отключенная</a>-->
<!--        </li>-->
<!--      </ul>-->
      <!-- <RouterLink class="d-flex btn btn-outline-danger" activeClass="active" aria-current="page" to="/alerts" style="margin-right: 5px;"><i class="bi bi-bell"> 5</i></RouterLink> -->
      <form class="d-flex">
        <input class="form-control me-2" type="search" placeholder="Поиск" aria-label="Поиск" name="q" v-model="q">
        <button class="btn btn-outline-success" type="submit" @click.stop.prevent="submit()">Поиск</button>
      </form>
    </div>
  </div>
</nav>
</template>

<script>
export default {
  name: 'NavBar',
  props: ['title', 'breadcrumbs'],
  data(){
    return {
      q : this.$route.query.q || ''
    }
  },
  methods: {
    submit(){
      //if you want to send any data into server before redirection then you can do it here
      this.$router.push("/search?q="+this.q);
    }
  }
}
</script>