import http from "@/helpmate/services/http-common";


class ContractTotalTrebDataService {

    getData(queryParams) {
        return http.get(`/total_cash_treb/`, {params: queryParams});
    }

}

export default new ContractTotalTrebDataService();
