import AbstractDataService from "@/helpmate/services/AbstractDataService";
import http from "@/helpmate/services/http-common";

class GroupDataService extends AbstractDataService {

    constructor() {
        super('/staffs/group');
    }

    resetToDefault() {
        return http.post(`${this.api_url}/reset_to_default/`);
    }

    setPermissions(groups) {
        return http.post(`${this.api_url}/set_permissions/`, groups);
    }

}

export default new GroupDataService();
