import http from "@/helpmate/services/http-common";


class ContractTotalFactDataService {

    getData(queryParams) {
        return http.get(`/total_cash/`, {params: queryParams});
    }

}

export default new ContractTotalFactDataService();
