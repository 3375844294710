<script setup>
import { STATUS_NEW, STATUS_IN_PROGRESS, STATUS_COMPLETED, STATUS_CLOSED, STATUS_CANCELLED, STATUS_PAUSED } from '@/constants';
</script>

<template>
  <span :class="{
    'badge text-bg-secondary': status === STATUS_NEW,
    'badge text-bg-warning': status === STATUS_IN_PROGRESS,
    'badge text-bg-success': status === STATUS_COMPLETED,
    'badge text-bg-dark': status === STATUS_CLOSED || status === STATUS_CANCELLED,
    'badge text-bg-danger': status === STATUS_PAUSED
  }">{{ status_text }}</span>
</template>

<script>

export default {
    name: 'TaskStatusBadge',
    props: {
        status: { type: Number, required: true },
        status_text: { type: String, required: true }
    }
}
</script>