<template>
  <NavBar :title="'Настройки'"></NavBar>
  <div class="container">
    <div>
        <RouterLink class="btn btn-secondary" to="/settings/acl">Настройки прав доступа</RouterLink>
    </div>
    <div class="mt-2">
      <RouterLink class="btn btn-secondary" to="/settings/user-group">Назначение группы пользователям</RouterLink>
    </div>
  </div>
</template>

<script>

import NavBar from "@/components/NavBar.vue";

export default {
  name: 'SettingsView',
  components: {NavBar},
};
</script>
