import {formatDate, formatDateTime, formatLink, formatPrice, formatPriceInt, formatTimeAgo} from "@/helpmate/utils";
import { STATUS_NEW, STATUS_IN_PROGRESS, STATUS_COMPLETED, STATUS_CLOSED, STATUS_PAUSED, STATUS_CANCELLED } from '@/constants';

const formatField = (value, column={}, row={}) => {
    const fieldType = column.fieldType
    let formattedValue = ''
    let titleValue = ''
    let cssClasses = ''
    let linkData = {}
    if (fieldType === 'text') {
        formattedValue = value
    } else if (fieldType === 'date') {
        formattedValue = formatDate(value)
        if (column.highlightExpired && value && new Date(value) < new Date()) {
            cssClasses = 'helpmate-cell-red'
        }
    } else if (fieldType === 'datetime') {
        formattedValue = formatDateTime(value)
        if (column.highlightExpired && value && new Date(value) < new Date()) {
            cssClasses = 'helpmate-cell-red'
        }
    } else if (fieldType === 'timeago') {
        formattedValue = formatTimeAgo(value)
        titleValue = formatDateTime(value)
    } else if (fieldType === 'price') {
        formattedValue = formatPrice(value)
        cssClasses = 'helpmate-cell-number'
        if (column.highlightMoreThenField && value > row[column.highlightMoreThenField]) {
            cssClasses += ' helpmate-cell-red'
        }
    } else if (fieldType === 'priceint') {
        formattedValue = formatPriceInt(value)
        cssClasses = 'helpmate-cell-number'
        if (column.highlightMoreThenField && value > row[column.highlightMoreThenField]) {
            cssClasses += ' helpmate-cell-red'
        }
    } else if (fieldType === 'link') {
        formattedValue = formatLink(value, column)
        linkData = column.linkTemplate.replace('#ID#', value)
        cssClasses = ''
    } else if (fieldType === 'status') {
        if (value === STATUS_NEW) {
            formattedValue = 'Новая'
        } else if (value === STATUS_IN_PROGRESS) {
            formattedValue = 'В работе'
        } else if (value === STATUS_COMPLETED) {
            formattedValue = 'Выполнена'
        } else if (value === STATUS_CLOSED) {
            formattedValue = 'Закрыта'
        } else if (value === STATUS_PAUSED) {
            formattedValue = 'Приостановлена'
        } else if (value === STATUS_CANCELLED) {
            formattedValue = 'Отменена'
        }
        cssClasses = ''
    } else if (fieldType === 'fk') {
        if (value) {
            formattedValue = value[column.fkFieldTitle] ? value[column.fkFieldTitle] : value.title
        } else {
            formattedValue = '-'
        }
        cssClasses = ''
    } else {
        formattedValue = '- Неизвестный тип поля -'
    }
    return {
        formattedValue,
        titleValue,
        cssClasses,
        linkData,
    }
}

export default formatField;