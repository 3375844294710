<template>
  <NavBar :title="'Настройки доступа'"></NavBar>
<!--    <RouterLink to="/acts/">Новый</RouterLink>-->

  <div class="container">
  <table class="table">
    <thead>
    <tr>
      <th>Наименование доступа</th>
      <th v-for="group in groups" :key="group.id">{{ group.name }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="permission in permissionsMap" :key="permission.title">
        <td>{{ permission.title }}</td>
        <td v-for="group in groups" :key="group.id">
          <div v-for="permission in permission.permissions" :key="permission.codename"><label class="form-check-label"><input v-model="group.permissions_codenames_map[permission.codename]" type="checkbox" class="form-check-input"> {{ permission.title }}</label></div>
        </td>
      </tr>
    </tbody>
  </table>

  <button class="btn btn-primary" @click="save">Сохранить</button>

    <button class="btn btn-warning" style="margin-left: 10px;" @click="resetToDefault">Сбросить права (выставить "по умолчанию")</button>
  </div>

</template>

<script>
import GroupDataService from "@/services/GroupDataService";
import NavBar from "@/components/NavBar.vue";

export default {
  name: 'SettingsACLView',
  components: {NavBar},
  data() {
    return {
      permissionsMap: [
        {
          title: 'Задачи',
          permissions: [
            {
              title: 'Создание',
              codename: 'add_task'
            },
            {
              title: 'Просмотр своих (автор)',
              codename: 'view_own_task'
            },
            {
              title: 'Изменение своих (автор)',
              codename: 'change_own_task'
            },
            {
              title: 'Удаление своих (автор)',
              codename: 'delete_own_task'
            },
            {
              title: 'Просмотр своих (исполнитель)',
              codename: 'view_own_contractor_task'
            },
            {
              title: 'Изменение своих (исполнитель)',
              codename: 'change_own_contractor_task'
            },
            {
              title: 'Удаление своих (исполнитель)',
              codename: 'delete_own_contractor_task'
            },
            {
              title: 'Просмотр задач по своему договору',
              codename: 'view_own_contract_task'
            },
            {
              title: 'Изменение задач по своему договору',
              codename: 'change_own_contract_task'
            },
            {
              title: 'Удаление задач по своему договору',
              codename: 'delete_own_contract_task'
            },
            {
              title: 'Просмотр всех',
              codename: 'view_task'
            },
            {
              title: 'Изменение всех',
              codename: 'change_task'
            },
            {
              title: 'Удаление всех',
              codename: 'delete_task'
            },
          ]
        },
        {
          title: 'Комментарии',
          permissions: [
            {
              title: 'Создание',
              codename: 'add_comment'
            },
            {
              title: 'Просмотр своих',
              codename: 'view_own_comment'
            },
            {
              title: 'Изменение своих',
              codename: 'change_own_comment'
            },
            {
              title: 'Удаление своих',
              codename: 'delete_own_comment'
            },
            {
              title: 'Просмотр комментариев по своему договору',
              codename: 'view_own_contract_comment'
            },
            {
              title: 'Изменение комментариев по своему договору',
              codename: 'change_own_contract_comment'
            },
            {
              title: 'Удаление комментариев по своему договору',
              codename: 'delete_own_contract_comment'
            },
            {
              title: 'Просмотр всех',
              codename: 'view_comment'
            },
            {
              title: 'Изменение всех',
              codename: 'change_comment'
            },
            {
              title: 'Удаление всех',
              codename: 'delete_comment'
            },
          ]
        },
        {
          title: 'Журнал работ',
          permissions: [
            {
              title: 'Создание',
              codename: 'add_worklog'
            },
            {
              title: 'Просмотр своих',
              codename: 'view_own_worklog'
            },
            {
              title: 'Изменение своих',
              codename: 'change_own_worklog'
            },
            {
              title: 'Удаление своих',
              codename: 'delete_own_worklog'
            },
            {
              title: 'Просмотр журнала работ по своему договору',
              codename: 'view_own_contract_worklog'
            },
            {
              title: 'Изменение журнала работ по своему договору',
              codename: 'change_own_contract_worklog'
            },
            {
              title: 'Удаление журнала работ по своему договору',
              codename: 'delete_own_contract_worklog'
            },
            {
              title: 'Просмотр всех',
              codename: 'view_worklog'
            },
            {
              title: 'Изменение всех',
              codename: 'change_worklog'
            },
            {
              title: 'Удаление всех',
              codename: 'delete_worklog'
            },
          ]
        },
        {
          title: 'Акты',
          permissions: [
            {
              title: 'Создание',
              codename: 'add_act'
            },
            {
              title: 'Просмотр своих',
              codename: 'view_own_act'
            },
            {
              title: 'Изменение своих',
              codename: 'change_own_act'
            },
            {
              title: 'Удаление своих',
              codename: 'delete_own_act'
            },
            {
              title: 'Просмотр актов по своему договору',
              codename: 'view_own_contract_act'
            },
            {
              title: 'Изменение актов по своему договору',
              codename: 'change_own_contract_act'
            },
            {
              title: 'Удаление актов по своему договору',
              codename: 'delete_own_contract_act'
            },
            {
              title: 'Просмотр всех',
              codename: 'view_act'
            },
            {
              title: 'Изменение всех',
              codename: 'change_act'
            },
            {
              title: 'Удаление всех',
              codename: 'delete_act'
            },
          ]
        },
        {
          title: 'Дополнительные расходы',
          permissions: [
            {
              title: 'Создание',
              codename: 'add_additional_cost'
            },
            {
              title: 'Просмотр своих',
              codename: 'view_own_additional_cost'
            },
            {
              title: 'Изменение своих',
              codename: 'change_own_additional_cost'
            },
            {
              title: 'Удаление своих',
              codename: 'delete_own_additional_cost'
            },
            {
              title: 'Просмотр дополнительных расходов по своему договору',
              codename: 'view_own_contract_additional_cost'
            },
            {
              title: 'Изменение дополнительных расходов по своему договору',
              codename: 'change_own_contract_additional_cost'
            },
            {
              title: 'Удаление дополнительных расходов по своему договору',
              codename: 'delete_own_contract_additional_cost'
            },
            {
              title: 'Просмотр всех',
              codename: 'view_additional_cost'
            },
            {
              title: 'Изменение всех',
              codename: 'change_additional_cost'
            },
            {
              title: 'Удаление всех',
              codename: 'delete_additional_cost'
            },
          ]
        },
        {
          title: 'Договоры',
          permissions: [
            {
              title: 'Создание',
              codename: 'add_contract'
            },
            {
              title: 'Просмотр своих',
              codename: 'view_own_contract'
            },
            {
              title: 'Изменение своих',
              codename: 'change_own_contract'
            },
            {
              title: 'Удаление своих',
              codename: 'delete_own_contract'
            },
            {
              title: 'Просмотр всех',
              codename: 'view_contract'
            },
            {
              title: 'Изменение всех',
              codename: 'change_contract'
            },
            {
              title: 'Удаление всех',
              codename: 'delete_contract'
            },
          ]
        },
        {
          title: 'Платежи',
          permissions: [
            {
              title: 'Создание',
              codename: 'add_payment'
            },
            {
              title: 'Просмотр своих',
              codename: 'view_own_payment'
            },
            {
              title: 'Изменение своих',
              codename: 'change_own_payment'
            },
            {
              title: 'Удаление своих',
              codename: 'delete_own_payment'
            },
            {
              title: 'Просмотр платежей по своему договору',
              codename: 'view_own_contract_payment'
            },
            {
              title: 'Изменение платежей по своему договору',
              codename: 'change_own_contract_payment'
            },
            {
              title: 'Удаление платежей по своему договору',
              codename: 'delete_own_contract_payment'
            },
            {
              title: 'Просмотр всех',
              codename: 'view_payment'
            },
            {
              title: 'Изменение всех',
              codename: 'change_payment'
            },
            {
              title: 'Удаление всех',
              codename: 'delete_payment'
            },
          ]
        },
        {
          title: 'Субподряды',
          permissions: [
            {
              title: 'Создание',
              codename: 'add_subcontract'
            },
            {
              title: 'Просмотр своих',
              codename: 'view_own_subcontract'
            },
            {
              title: 'Изменение своих',
              codename: 'change_own_subcontract'
            },
            {
              title: 'Удаление своих',
              codename: 'delete_own_subcontract'
            },
            {
              title: 'Просмотр субподрядов по своему договору',
              codename: 'view_own_contract_subcontract'
            },
            {
              title: 'Изменение субподрядов по своему договору',
              codename: 'change_own_contract_subcontract'
            },
            {
              title: 'Удаление субподрядов по своему договору',
              codename: 'delete_own_contract_subcontract'
            },
            {
              title: 'Просмотр всех',
              codename: 'view_subcontract'
            },
            {
              title: 'Изменение всех',
              codename: 'change_subcontract'
            },
            {
              title: 'Удаление всех',
              codename: 'delete_subcontract'
            },
          ]
        },
        {
          title: 'Дополнительные соглашения',
          permissions: [
            {
              title: 'Создание',
              codename: 'add_supplementary_agreement'
            },
            {
              title: 'Просмотр своих',
              codename: 'view_own_supplementary_agreement'
            },
            {
              title: 'Изменение своих',
              codename: 'change_own_supplementary_agreement'
            },
            {
              title: 'Удаление своих',
              codename: 'delete_own_supplementary_agreement'
            },
            {
              title: 'Просмотр дополнительных соглашений по своему договору',
              codename: 'view_own_contract_supplementary_agreement'
            },
            {
              title: 'Изменение дополнительных соглашений по своему договору',
              codename: 'change_own_contract_supplementary_agreement'
            },
            {
              title: 'Удаление дополнительных соглашений по своему договору',
              codename: 'delete_own_contract_supplementary_agreement'
            },
            {
              title: 'Просмотр всех',
              codename: 'view_supplementary_agreement'
            },
            {
              title: 'Изменение всех',
              codename: 'change_supplementary_agreement'
            },
            {
              title: 'Удаление всех',
              codename: 'delete_supplementary_agreement'
            },
          ]
        },
        {
          title: 'Командировочные расходы',
          permissions: [
            {
              title: 'Создание',
              codename: 'add_travel_expense'
            },
            {
              title: 'Просмотр своих',
              codename: 'view_own_travel_expense'
            },
            {
              title: 'Изменение своих',
              codename: 'change_own_travel_expense'
            },
            {
              title: 'Удаление своих',
              codename: 'delete_own_travel_expense'
            },
            {
              title: 'Просмотр командировочных расходов по своему договору',
              codename: 'view_own_contract_travel_expense'
            },
            {
              title: 'Изменение командировочных расходов по своему договору',
              codename: 'change_own_contract_travel_expense'
            },
            {
              title: 'Удаление командировочных расходов по своему договору',
              codename: 'delete_own_contract_travel_expense'
            },
            {
              title: 'Просмотр всех',
              codename: 'view_travel_expense'
            },
            {
              title: 'Изменение всех',
              codename: 'change_travel_expense'
            },
            {
              title: 'Удаление всех',
              codename: 'delete_travel_expense'
            },
          ]
        },
        {
          title: 'Пользователи',
          permissions: [
            {
              title: 'Создание',
              codename: 'add_user'
            },
            {
              title: 'Просмотр',
              codename: 'view_user'
            },
            {
              title: 'Изменение',
              codename: 'change_user'
            },
            {
              title: 'Удаление',
              codename: 'delete_user'
            },
          ]
        },
        {
          title: 'Зарплата',
          permissions: [
            {
              title: 'Создание',
              codename: 'add_staffsalary'
            },
            {
              title: 'Просмотр',
              codename: 'view_staffsalary'
            },
            {
              title: 'Изменение',
              codename: 'change_staffsalary'
            },
            {
              title: 'Удаление',
              codename: 'delete_staffsalary'
            },
          ]
        }
      ],
      groups: [],
    };
  },
  methods: {
    save() {
      // set permissions codenames
      this.groups.forEach(group => {
        group.permissions_codenames = Object.keys(group.permissions_codenames_map).filter(key => group.permissions_codenames_map[key]);
      });
      // save
      GroupDataService.setPermissions(this.groups).then(() => {
        this.loadGroups();
      }).catch(error => {
        console.log(error);
      });
      
    },
    resetToDefault() {
      if (confirm('Вы действительно хотите сбросить права доступа? Это удалит все права доступа и установит их по умолчанию.')) {
      GroupDataService.resetToDefault().then(() => {
        this.loadGroups();
        }).catch(error => {
          console.log(error);
        });
      }
    },
    loadGroups() {
      GroupDataService.getAll().then(response => {
        this.groups = response.data.results;
        this.groups.forEach(group => {
          group.permissions_codenames_map = this.permissionsMap.reduce((acc, permissionGroup) => {
            permissionGroup.permissions.forEach(permission => {
              acc[permission.codename] = group.permissions_codenames.includes(permission.codename);
            });
            return acc;
          }, {});
        });
      }).catch(error => {
        console.log(error);
      });
    },
  },
  mounted() {
    this.loadGroups();
  },
};
</script>
