<template>
  <NavBar :title="'Назначение группы пользователям'"></NavBar>
  <div class="container">
    <table class="table">
      <thead>
        <tr>
          <th>Пользователь</th>
          <th v-for="group in groups" :key="group.id">Роль: {{ group.name }}</th>
          <th>Администратор</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.last_name }} {{ user.first_name }} ({{ user.email }})</td>
          <td v-for="group in groups" :key="group.id">
            <button v-if="user.groups.includes(group.id)" class="btn btn-primary" @click="removeGroup(user, group)">
              Назначено
            </button>
            <button v-else class="btn btn-light" @click="assignGroup(user, group)">
              Отсутствует
            </button>
          </td>
          <td>
            <button v-if="user.is_superuser" class="btn btn-primary" @click="removeSuperuser(user)">
              Назначено
            </button>
            <button v-else class="btn btn-light" @click="assignSuperuser(user)">
              Отсутствует
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import NavBar from "@/components/NavBar.vue";
import GroupDataService from "@/services/GroupDataService";
import UserDataService from "@/services/UserDataService";
export default {
  name: 'SettingsUserGroupView',
  components: {NavBar},
  data() {
    return {
      groups: [],
      users: [],
    };
  },
  methods: {
    loadGroups() {
      GroupDataService.getAll().then(response => {
        this.groups = response.data.results;
      });
    },
    loadUsers() {
      UserDataService.getAll().then(response => {
        this.users = response.data.results;
      });
    },
    assignGroup(user, group) {
      UserDataService.assignGroup(user.id, group.id).then(() => {
        this.loadUsers();
      }).catch(() => {
        this.$store.commit('addToast', {title: "Ошибка!", message:"Действие не выполнено. У вас достаточно прав?"});
      });
    },
    removeGroup(user, group) {
      UserDataService.removeGroup(user.id, group.id).then(() => {
        this.loadUsers();
      }).catch(() => {
        this.$store.commit('addToast', {title: "Ошибка!", message:"Действие не выполнено. У вас достаточно прав?"});
      });
    },
    assignSuperuser(user) {
      UserDataService.assignSuperuser(user.id).then(() => {
        this.loadUsers();
      }).catch(() => {
        this.$store.commit('addToast', {title: "Ошибка!", message:"Действие не выполнено. У вас достаточно прав?"});
      });
    },
    removeSuperuser(user) {
      UserDataService.removeSuperuser(user.id).then(() => {
        this.loadUsers();
      }).catch(() => {
        this.$store.commit('addToast', {title: "Ошибка!", message:"Действие не выполнено. У вас достаточно прав?"});
      });
    },
  },
  mounted() {
    this.loadGroups();
    this.loadUsers();
  },
};
</script>
