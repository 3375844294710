<script setup>
import HelpmateSpinner from "@/helpmate/components/HelpmateSpinner.vue";

</script>

<template>

  <HelpmateSpinner :isLoading="isLoading"></HelpmateSpinner>

  <div v-if="!isLoading">
    <table class="table">
      <thead>
      <tr>
        <th>№</th>
        <th>Наименование</th>
        <th>Стоимость, руб.</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>1</td>
        <td>Расходы на оплату труда</td>
        <td>{{ data.total_fot }}</td>
      </tr>
      <tr>
        <td>2</td>
        <td>Взносы в социальные фонды</td>
        <td>{{ data.total_soc_fond }}</td>
      </tr>
      <tr>
        <td>3</td>
        <td>Страховые взносы на обязательное социальное страхование от несчастных случаев на производстве</td>
        <td>{{ data.total_strah }}</td>
      </tr>
      <tr>
        <td>4</td>
        <td>Командировочные расходы</td>
        <td>{{ data.total_travel }}</td>
      </tr>
      <tr>
        <td>5</td>
        <td>Итого по расходам</td>
        <td>{{ data.total_without_nds }}</td>
      </tr>
      <tr>
        <td>6</td>
        <td>Стоимость затрат с НДС</td>
        <td>{{ data.total_with_nds }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>


import ContractTotalFactDataService from "@/services/ContractTotalFactDataService";
import {formatPriceInt} from "@/helpmate/utils";

export default {
  name: 'ContractTotalFact',
  props: {
    contractId: {
      type: [Number, String],
      required: false,
    },
    autoUpdate: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: [
    // HelpmateSpinner
  ],
  data() {
    return {
      isLoading: true,
      interval: null,
      data: {
        total_fot: 0,  // Расходы на оплату труда
        total_soc_fond: 0,  // Взносы в социальные фонды
        total_strah: 0,  // Страховые взносы на обязательное социальное страхование от несчастных случаев на производстве
        total_travel: 0,  // Командировочные расходы
        total_without_nds: 0,  // Итого по расходам без НДС
        total_with_nds: 0,  // Стоимость затрат с НДС
      }
    }
  },
  methods: {
    updateData(changeLoading = true) {
      this.isLoading = changeLoading ? true : this.isLoading
      let queryParams = {}
      if (this.contractId) {
        queryParams.contractId = this.contractId
      }
      ContractTotalFactDataService.getData(queryParams)
          .then((response) => {
            this.data = {...response.data}
            Object.keys(this.data).forEach((key) => {
              this.data[key] = formatPriceInt(this.data[key]);
            });
            this.isLoading = false
          })
          .catch(e => {
            console.log(e);
          });
    },
  },
  mounted() {
    this.updateData()
    if (this.autoUpdate) {
      this.interval = setInterval(() => {
        this.updateData(false)
      }, 3000)
    }
  },
  beforeUnmount() {
    clearInterval(this.interval)
  }
}
</script>


<style scoped>

</style>