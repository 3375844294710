<template>

    <HelpmateTable :tableContext="tableContext" :dataService="SalaryDataService()" :columns="columns"></HelpmateTable>

</template>

<script>
import SalaryDataService from '@/services/SalaryDataService';
import HelpmateTable from "@/helpmate/components/HelpmateTable.vue";

export default {
  name: 'SalaryListComponent',
  components: {
    HelpmateTable,
  },
  props: {
    tableContext: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      columns: [
        {field: 'monthly_amount', fieldType: 'priceint', title: 'Зарплата в месяц'},
        {field: 'date_at', fieldType: 'date', title: 'Дата изменения зарплаты'},
      ],
    }
  },
  methods: {
    SalaryDataService() {
      return SalaryDataService
    },
  }
};
</script>
