<template>

<!--    <HelpmateTable :data="objects" :headers="['', '№', 'Фамилия', 'Имя', 'Должность', 'Дата регистрации', 'Последняя активность', 'Задачи', 'Зарплата, руб./мес.', 'Стоимость часа в текущем месяце, руб.']"></HelpmateTable>-->
    <HelpmateTable :dataService="UserDataService()" :columns="columns"></HelpmateTable>

<!--      <tbody>-->
<!--      <tr-->
<!--          v-for="(obj, index) in objects"-->
<!--          :key="index"-->
<!--      >-->
<!--        <td><input type="checkbox" v-model="obj.selected"></td>-->
<!--        <td>{{ index + 1 }}</td>-->
<!--        <td>{{ obj.last_name }}</td>-->
<!--        <td>{{ obj.first_name }}</td>-->
<!--        <td>{{ obj.staff_position_title }}</td>-->
<!--        <td>{{ $filters.formatDateTime(obj.date_joined) }}</td>-->
<!--        <td><span :title="$filters.formatDateTime(obj.last_login)">{{ $filters.timeAgo(obj.last_login) }}</span></td>-->
<!--        <td>-->
<!--          <RouterLink :to="{ name: 'UserTaskListView', params: { id: obj.id }}">Задачи сотрудника</RouterLink>-->
<!--        </td>-->
<!--        <td style="text-align: right; font-family: Lucida Console;">-->
<!--          <a href="#">{{ $filters.formatNumberWithSpaces(obj.salary_monthly_amount) }}</a>-->
<!--        </td>-->
<!--        <td style="text-align: right; font-family: Lucida Console;">-->
<!--          {{ $filters.formatNumberWithSpaces(obj.salary_hourly_amount, 2) }}-->
<!--        </td>-->
<!--      </tr>-->
<!--      </tbody>-->

</template>

<script>
import UserDataService from '@/services/UserDataService';
import HelpmateTable from "@/helpmate/components/HelpmateTable.vue";
// import HelpmateTable from "@/helpmate/components/HelpmateTable.vue";

export default {
  name: 'UserListComponent',
  components: {
    HelpmateTable,
  },
  data() {
    return {
      columns: [
        {field: 'id', fieldType: 'link', title: 'Подробнее', linkTemplate: '/users/detail/#ID#', readonly: true},
        {field: 'username', fieldType: 'text', title: 'Логин'},
        {field: 'email', fieldType: 'text', title: 'Email'},
        {field: 'last_name', fieldType: 'text', title: 'Фамилия'},
        {field: 'first_name', fieldType: 'text', title: 'Имя'},
        {field: 'staff_position_title', fieldType: 'text', title: 'Должность', readonly: true},
        {field: 'date_joined', fieldType: 'datetime', title: 'Дата регистрации', readonly: true},
        {field: 'last_login', fieldType: 'timeago', title: 'Последняя активность', readonly: true},
        {field: 'salary_monthly_amount', fieldType: 'priceint', title: 'Зарплата, руб./мес.', readonly: true, aggregate: 'sum'},
        {
          field: 'salary_hourly_amount',
          fieldType: 'price',
          title: 'Стоимость часа в текущем месяце, руб.',
          readonly: true,
          aggregate: 'sum',
        },
        {field: 'id', fieldType: 'link', title: 'Изменения зп', linkTemplate: '/users/#ID#/salary', readonly: true},
        {field: 'id', fieldType: 'link', title: 'Задачи', linkTemplate: '/users/#ID#/tasks', readonly: true},
      ],
    }
  },
  // computed: {
  //   totalAmount: function () {
  //     let sum = 0;
  //     this.objects.forEach(e => {
  //       sum += Number(e.amount);
  //     });
  //     return sum
  //   }
  // },
  methods: {
    UserDataService() {
      return UserDataService
    },
  },
};
</script>
