<script setup>
import TaskListComponent from '@/components/Tasks/Task/TaskListComponent.vue';
</script>

<template>
    <NavBar :title="'Дашборд'"></NavBar>

    <div class="container">
      <h5>Ваши задачи</h5>

      <div v-if="userId">
        <TaskListComponent :tableContext="tableContext"></TaskListComponent>
      </div>

      <h5>Все доступные задачи</h5>

      <TaskListComponent></TaskListComponent>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import UserDataService from "@/services/UserDataService";

export default {
  name: 'HomeView',
  components: {NavBar},
  data() {
    return {
      userId: 0,
    }
  },
  methods: {
    getProfile() {
      UserDataService.getProfile()
          .then(response => {
            this.userId = Number(response.data.id);
          })
          .catch(e => {
            console.log(e);
          });
    },
  },
  computed: {
    tableContext() {
      return {
        userContractorId: Number(this.userId),
        baseFilter: {
          user_contractor_id: Number(this.userId),
        }
      }
    }
  },
  mounted() {
    this.getProfile();
  },
};
</script>
